import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import {
  createStyles,
  Paper,
  Title,
  Button,
  useMantineTheme,
  rem,
} from "@mantine/core";

import dbspy from "../asset/dbspy.gif";

const useStyles = createStyles((theme) => ({
  card: {
    height: rem(440),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: "#0c1c34",
    lineHeight: 1.2,
    fontSize: rem(32),
    marginTop: theme.spacing.xs,
  },

  posttitle: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,
    textAlign: "center",
    marginBottom: "10px",

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  category: {
    color: theme.black,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

interface CardProps {
  image: string;
  title: string;
  category: string;
}

function Card({ image, title, category }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <div>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
      </div>
      <div>
        <a
          href="http://db-spy.io/"
          style={{ textDecoration: "none", color: "white" }}
        >
          <Button
            variant="filled"
            color="yellow"
            style={{ marginRight: "15px" }}
          >
            Website
          </Button>
        </a>

        <a
          href="https://medium.com/@tarik.mokhtech/introduction-to-data-modeling-and-dbspy-96d17ad53789"
          style={{ textDecoration: "none", color: "white" }}
        >
          <Button variant="filled" color="yellow">
            Read article
          </Button>
        </a>
      </div>
    </Paper>
  );
}

const data = [
  {
    image: dbspy,
    title: "dbSpy",
    category: "nature",
  },
];

export function CardsCarousel() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));
  const { classes } = useStyles();

  return (
    <div
      id="portfolio"
      style={{
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
      }}
    >
      <Title className={classes.posttitle}>Portfolio</Title>

      <Carousel
        slideSize="100%"
        breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: rem(2) }]}
        slideGap="xl"
        align="start"
        slidesToScroll={mobile ? 1 : 1}
      >
        {slides}
      </Carousel>
    </div>
  );
}
