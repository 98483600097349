import { useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Group,
  ActionIcon,
  Container,
  Burger,
  rem,
  Text,
  Menu,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandGithub,
  IconHome,
  IconAppWindow,
  IconAddressBook,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: rem(56),

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "flex-start",
    },
  },

  links: {
    width: rem(260),

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  social: {
    width: rem(260),

    [theme.fn.smallerThan("sm")]: {
      width: "auto",
      marginLeft: "auto",
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  title: {
    fontSize: "30px",
    fontWeight: 1000,
    letterSpacing: "5px",
  },
}));

interface HeaderMiddleProps {
  links: { link: string; label: string; id: string }[];
}

export function HeaderMiddle({ links }: HeaderMiddleProps) {
  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link)}
      onClick={(event) => {
        let section = document.getElementById(link.id);
        setActive(link.link);
        section &&
          section.scrollIntoView({ behavior: "smooth", block: "start" });
      }}
    >
      {link.label}
    </Link>
  ));

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <Header height={56} mb={120}>
      <Container className={classes.inner}>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Burger opened={opened} size="sm" className={classes.burger} />
          </Menu.Target>

          <Menu.Dropdown>
            <Link
              to="/about"
              style={{ textDecoration: "none" }}
              onClick={(event) => {
                let section = document.getElementById("about");
                section &&
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
              }}
            >
              <Menu.Item icon={<IconHome size={14} />}>About</Menu.Item>
            </Link>
            <Link
              to="/portfolio"
              style={{ textDecoration: "none" }}
              onClick={(event) => {
                let section = document.getElementById("portfolio");
                section &&
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
              }}
            >
              <Menu.Item icon={<IconAppWindow size={14} />}>
                Portfolio
              </Menu.Item>
            </Link>
            <Link
              to="/contact"
              style={{ textDecoration: "none" }}
              onClick={(event) => {
                let section = document.getElementById("contact");
                section &&
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
              }}
            >
              <Menu.Item icon={<IconAddressBook size={14} />}>
                Contact
              </Menu.Item>
            </Link>
          </Menu.Dropdown>
        </Menu>
        <Group className={classes.links} spacing={5}>
          {items}
        </Group>

        <Text className={classes.title}>KPL</Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.linkedin.com/in/kevin38424/"
          >
            <IconBrandLinkedin size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            component="a"
            href="https://github.com/kevin38424"
          >
            <IconBrandGithub size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.instagram.com/kparkj/"
          >
            <IconBrandInstagram size="1.1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.youtube.com/@user-rk8cw7ex4k/featured"
          >
            <IconBrandYoutube size="1.1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </Header>
  );
}
