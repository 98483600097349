import { Container } from "@mantine/core";
import { CardsCarousel } from "../components/Carausel";
import { Introduction } from "../components/Introduction";
import { LeadGrid } from "./Career";
import { GetInTouchSimple as Contact } from "./Contact";

const Home = () => {
  return (
    <Container>
      <Introduction />
      <LeadGrid />
      <CardsCarousel />
      <Contact />
    </Container>
  );
};

export default Home;
