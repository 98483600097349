import {
  Container,
  Grid,
  useMantineTheme,
  Text,
  Title,
  Image,
  createStyles,
} from "@mantine/core";
import KPL from "../asset/KPL.jpeg";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },
  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  grid: {
    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

export function LeadGrid() {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Container
      my="md"
      style={{ paddingBottom: `calc(${theme.spacing.xl} * 4)` }}
    >
      <Grid gutter="md" columns={10} className={classes.inner}>
        <Grid.Col span={7} className={classes.grid}>
          <Title order={2} style={{ margin: "5px 0px" }} italic>
            Education
          </Title>
          <Text>
            <b>2013 - 2017</b> Georgia Institute of Technology BSChBE
          </Text>
          <Title
            order={2}
            style={{ marginTop: "15px", marginBottom: "5px" }}
            italic
          >
            Career
          </Title>
          <Text>
            <b>2022 - {new Date().getFullYear()}</b> Sr. Software Engineer |{" "}
            <i>Capital One</i>
          </Text>
          <Text>
            <b>2018 - 2022</b> Technology &amp; Application Consultant |{" "}
            <i>Mettler Toledo AutoChem Inc.</i>
          </Text>
          <Text>
            <b>2017 - 2018</b> Process Engineer | <i>Armstrong Flooring</i>
          </Text>
          <Title
            order={2}
            style={{ marginTop: "15px", marginBottom: "5px" }}
            italic
          >
            Certification
          </Title>
          <Text>
            <b>2022</b> CSSE
          </Text>
          <Text>
            <b>2023</b> AWS Solutions Architect Associate
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Image src={KPL} className={classes.image} />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
