import { ThemeProvider } from "./ThemeProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { HeaderMiddle as Header } from "./components/Header";
import { FooterSocial as Footer } from "./components/Footer";
import Home from "./pages/Home";

export default function App() {
  return (
    <ThemeProvider>
      <Router>
        <Header
          links={[
            { link: "/about", label: "About", id: "about" },
            { link: "/portfolio", label: "Portfolio", id: "portfolio" },
            { link: "/contact", label: "Contact", id: "contact" },
          ]}
        />
        <Home />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
