import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  rem,
} from "@mantine/core";
import {
  IconDeviceLaptop,
  IconPlaneTilt,
  IconMicrophone2,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import KPL from "../asset/KPL3.jpeg";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      alignItems: "center",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export function Introduction() {
  const { classes } = useStyles();
  return (
    <div id="about">
      <Container mb={120}>
        <div className={classes.inner}>
          <Image
            src={KPL}
            style={{
              width: "400px",
            }}
            className={classes.image}
          />

          <div className={classes.content}>
            <Title className={classes.title}>Kevin Jiho Park-Lee</Title>
            <Text color="dimmed" mt="md">
              Hi! Nice to meet you. Here are the three keywords about me:
            </Text>

            <List mt={15} spacing="sm" size="sm">
              <List.Item icon={<IconDeviceLaptop />}>
                <b>Software Engineer</b> – I am a full stack engineer
                specializing in React, JavaScript, TypeScript, Python, Spring
                Boot, and many AWS Services.
              </List.Item>
              <List.Item icon={<IconPlaneTilt />}>
                <b>World Traveller</b> – I have travelled to 16+ different
                countries around the world since 2017!
              </List.Item>
              <List.Item icon={<IconMicrophone2 />}>
                <b>Public Speaker</b> – I love to share my knowledge and stories
                with others.
              </List.Item>
            </List>

            <Group mt={30} position="right">
              <Link
                to="/contact"
                onClick={(event) => {
                  let section = document.getElementById("contact");
                  section &&
                    section.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                }}
              >
                <Button
                  variant="default"
                  radius="xl"
                  size="md"
                  className={classes.control}
                >
                  Let's Connect!
                </Button>
              </Link>
            </Group>
          </div>
        </div>
      </Container>
    </div>
  );
}
